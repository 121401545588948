import styled, { css } from "styled-components";
import { device } from "../styles/stylesConfig";
import { useAnimations } from "../effects/Effects";
import { useHistory, useLocation } from "react-router-dom";
import { useLogEvent } from "../hooks/useLogEvent";
import { memo } from "react";

const MenuContainer = styled.div<{ show: string }>`
    width: 100%;
    height: 80vh;
    display: flex;
    color: white;
    align-items: start;
    flex-direction: column;
    justify-content: space-evenly;
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 1;
    visibility: hidden;
    padding: 10vh 30px;

    @media ${device.lg} {
        visibility: visible;
    }

    @keyframes openModal {
        0% {
            left: 100%;
            background: rgba(0,0,0,0);
        }
        100% {
            left: 0%;
            background: rgba(0,0,0,0.9);
        }
    }

    @keyframes closeModal {
        0% {
            left: 0%;
            background: rgba(0,0,0,0.9);
        }
        100% {
            left: 100%;
            background: rgba(0,0,0,0);

        }
    }

    @keyframes closeText {
        0% {
            font-size: 28px;
            @media ${device.xs} {
                font-size: 20px;
            }
        }
        100% {
            font-size: 0px;
        }
    }

    ${({ show }) => show === 'false' && css`

        & > div {
            font-size: 0px;
            animation: closeText 0.2s normal;
            visibility: hidden;
        }
        display: none;
        animation: closeModal 0.3s normal;


    `}

    ${({ show }) => show === 'true' && css`
        display: flex;
        left: 0%;
        animation: openModal 0.3s normal;
        background: rgba(0,0,0,0.9);

    `}
`

const NavButtonDiv = styled.div`

    & > div:first-of-type {
        padding-bottom: 0px;
    }
`

const NavButton = styled.div<{ screen: string }>`
    color: white;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 5px;
    padding: 15px 0px;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: start;
    text-transform: uppercase   ;
    
    &:focus,    
    &:hover {
        color: #ff0c19;

    }

    @media ${device.sm} {
        font-size: 16px;
    }

    ${({ screen }) => screen === 'true' && css`
        color: #ff0c19;
        font-weight: bold;
    `}

`

const SubNavButton = styled.div<{ screen: string }>`
    color: white;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 3px;
    margin-top: 20px;
    margin-left: 5px;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: start;
    
    &:focus,    
    &:hover {
        color: #ff0c19;

    }

    @media ${device.sm} {
        font-size: 12px;
    }

    ${({ screen }) => screen === 'true' && css`
        color: #ff0c19;
        font-weight: bold;
    `}

`

interface Props {
    showMenu: boolean;
    setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuComponent = ({ showMenu, setShowMenu }: Props) => {

    const {
        handleClickGoHome,
        handleClickGoHomeInstant,
        handleClickGoAbout,
        handleClickGoAboutInstant,
        handleClickGoProducts,
        handleClickGoProductsInstant,
        handleClickGoJobs,
        handleClickGoJobsInstant,
        handleClickGoContact,
        handleClickGoContactInstant,
        handleClickGoQuotator,
        handleClickGoQuotatorInstant,
    } = useAnimations()

    const history: any = useHistory();

    const location = useLocation();

    const { handleLogEvent } = useLogEvent();

    return (

        <MenuContainer show={showMenu.toString()}>

            <NavButton screen={(location.pathname === "/").toString()} onClick={async () => {

                if (location.pathname !== "/") {
                    await history.push('/');

                    setTimeout(() => {
                        handleClickGoHomeInstant()
                    }, 10);
                } else {
                    handleClickGoHome();
                }

                handleLogEvent('click_boton_inicio_menu');

                setShowMenu(false);

            }}>
                Inicio
            </NavButton>

            <NavButton screen={(location.pathname === "/nosotros").toString()} onClick={async () => {

                if (location.pathname !== "/nosotros") {
                    await history.push('/nosotros');

                    setTimeout(() => {
                        handleClickGoAboutInstant()
                    }, 10);
                } else {
                    handleClickGoAbout();
                }

                handleLogEvent('click_boton_nosotros_menu');

                setShowMenu(false);
            }}>
                Nosotros
            </NavButton>

            <NavButtonDiv>

                <NavButton screen={(location.pathname === "/productos").toString()} onClick={async () => {

                    if (location.pathname !== "/productos") {
                        await history.push('/productos');

                        setTimeout(() => {
                            handleClickGoProductsInstant()
                        }, 10);
                    } else {
                        handleClickGoProducts();
                    }

                    handleLogEvent('click_boton_productos_menu');

                    setShowMenu(false);
                }}>
                    Productos
                </NavButton>

                <SubNavButton screen={(location.pathname === "/productos/pvc").toString()} onClick={async () => {

                    if (location.pathname !== "/productos/pvc") {
                        await history.push('/');
                        await history.push('/productos/pvc');

                        setTimeout(() => {
                            handleClickGoProductsInstant()
                        }, 10);
                    } else {
                        handleClickGoProducts();
                    }

                    setShowMenu(false);
                }}>
                    - Aberturas de pvc
                </SubNavButton>

                <SubNavButton screen={(location.pathname === "/productos/aluminio").toString()} onClick={async () => {

                    if (location.pathname !== "/productos/aluminio") {
                        await history.push('/');
                        await history.push('/productos/aluminio');

                        setTimeout(() => {
                            handleClickGoProductsInstant()
                        }, 10);
                    } else {
                        handleClickGoProducts();
                    }

                    setShowMenu(false);
                }}>
                    - Aberturas de aluminio
                </SubNavButton>

                <SubNavButton screen={(location.pathname === "/productos/herrajes").toString()} onClick={async () => {

                    if (location.pathname !== "/productos/herrajes") {
                        await history.push('/productos/herrajes');

                        setTimeout(() => {
                            handleClickGoProductsInstant()
                        }, 10);
                    } else {
                        handleClickGoProducts();
                    }

                    setShowMenu(false);
                }}>
                    - Herrajes
                </SubNavButton>

            </NavButtonDiv>



            <NavButton screen={(location.pathname === "/obras").toString()} onClick={async () => {

                if (location.pathname !== '/obras') {

                    history.push('/obras');

                    setTimeout(() => {
                        handleClickGoJobsInstant()
                    }, 10);

                } else {

                    handleClickGoJobs()

                }

                handleLogEvent('click_boton_obras_menu');

                setShowMenu(false);
            }}>
                Obras
            </NavButton>

            <NavButton screen={(location.pathname === "/contacto").toString()} onClick={async () => {

                if (location.pathname !== '/contacto') {

                    history.push('/contacto');

                    setTimeout(() => {
                        handleClickGoContactInstant()
                    }, 10);

                } else {

                    handleClickGoContact()

                }

                handleLogEvent('click_boton_contacto_menu');

                setShowMenu(false);
            }}>
                Contacto
            </NavButton>

            <NavButton screen={(location.pathname === "/cotizador").toString()} onClick={async () => {

                if (location.pathname !== '/cotizador') {

                    history.push('/cotizador');

                    setTimeout(() => {
                        handleClickGoQuotatorInstant()
                    }, 10);

                } else {

                    handleClickGoQuotator()

                }

                handleLogEvent('click_boton_cotizador_menu');

                setShowMenu(false);

            }}>
                Cotiza tu obra
            </NavButton>

        </MenuContainer>

    )
}

const Menu = memo(MenuComponent);

export default Menu;